import { RumInitConfiguration, datadogRum } from "@datadog/browser-rum";
import { DeploymentEnvironmentName, environmentConfig } from "@src/appV2/environment";
import { isProductionNodeEnvironment } from "@src/appV2/environment/utils";
import { getAppInfo } from "@src/appV2/lib";
import { Agent } from "@src/lib/interface";

const datadogRumInitConfig: RumInitConfiguration = {
  applicationId: environmentConfig.REACT_APP_DATADOG_RUM_APPLICATION_ID,
  clientToken: environmentConfig.REACT_APP_DATADOG_RUM_CLIENT_TOKEN,
  defaultPrivacyLevel: "mask-user-input",
  service: "hcp-mobile-app",
  version: environmentConfig.REACT_APP_DATADOG_RUM_SOURCEMAP_VERSION ?? "1.0.0",
  sessionSampleRate: 100,
  // if this is set, starts recording automatically when a session starts
  sessionReplaySampleRate: 1,
  trackUserInteractions: true,
  env: environmentConfig.REACT_APP_ENVIRONMENT_NAME,
  trackResources: true,
  trackLongTasks: true,
  allowedTracingUrls: [
    /^https:\/\/.*\.prod\.clipboardhealth\.org/,
    /^https:\/\/.*\.clipboard\.health/,
  ],
  /**
   * See https://github.com/DataDog/browser-sdk/issues/480
   * On some IOS versions, cookies are blocked, and DataDog Capacitor logging fails.
   * The recommended solution is to allow fallback to localStorage
   */
  allowFallbackToLocalStorage: true,
  enableExperimentalFeatures: ["feature_flags"],
};

export async function initDatadogRum() {
  if (
    isProductionNodeEnvironment() &&
    environmentConfig.REACT_APP_ENVIRONMENT_NAME === DeploymentEnvironmentName.PRODUCTION
  ) {
    const { version: appVersion, otaBuildId } = await getAppInfo();
    datadogRum.init({
      ...datadogRumInitConfig,
      beforeSend(event) {
        if (event.context) {
          event.context.appVersion = appVersion;
          event.context.otaBuildId = otaBuildId;
          event.context.screenWidth = window.screen.width;
          event.context.screenHeight = window.screen.height;
        }
        return true;
      },
    });
  }
}

export const clearDatadogRumUser = () => {
  datadogRum.clearUser();
};

export const identifyDatadogRumUser = (
  agent: Pick<Agent, "userId" | "name" | "email" | "address">
) => {
  datadogRum.setUser({
    id: agent.userId,
    name: agent.name,
    email: agent.email,
    msa: agent.address?.metropolitanStatisticalArea,
  });
};
