import type { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { environmentConfig } from "@src/appV2/environment";
import request from "superagent";

import { GET_PRESIGNED_UPLOAD_URL } from "../documents/gql";
import { IFileUpload } from "../documents/model";
import { getAuthHeader } from "../superagent";

const baseUrl = environmentConfig.REACT_APP_BASE_API_URL;

export const uploadFileToFileStorage = async (
  file: Blob,
  fileType: string,
  reqId: string,
  client: ApolloClient<NormalizedCacheObject>
): Promise<Pick<IFileUpload, "fileStorageRootFolder" | "fileStorageFileKey">> => {
  const contentType = getContentType[fileType.toLowerCase()];
  if (!contentType) {
    throw new Error("Invalid file extension");
  }
  const {
    data: {
      getPresignedUploadUrl: { preSignedUrl, fileStorageRootFolder, fileStorageFileKey },
    },
  } =
    (await client.query({
      query: GET_PRESIGNED_UPLOAD_URL,
      fetchPolicy: "network-only",
      variables: { reqId, fileType: fileType?.toLowerCase() },
    })) || {};
  if (!preSignedUrl) {
    throw new Error("Not able to fetch preSignedUrl");
  }

  await request.put(preSignedUrl).set("Content-Type", contentType).send(file);

  return { fileStorageRootFolder, fileStorageFileKey };
};
const generateRandomId = () => {
  /**
   * FIXME - Replace `window.randomUUID` with package import `uuid`
   * https://linear.app/clipboardhealth/issue/FEF-155/remove-windowcrypto-and-directly-use-uuid-package
   */
  // window.randomUUID was not found by vite bundler
  return window.crypto && (window.crypto as any).randomUUID
    ? (window.crypto as any).randomUUID()
    : Math.random().toString(36).slice(-6);
};

export const uploadTimesheetToS3Storage = async (fileBlob: Blob, type: string, shiftId: string) => {
  const randomId = generateRandomId();
  const fileStorageFileKey = `timesheet/${shiftId}/${randomId}.${type}`;
  const resp = await request
    .get(`${baseUrl}/shift/${shiftId}/timesheet/${fileStorageFileKey}/presigned-upload-url`)
    .set(await getAuthHeader());
  const preSignedUrl = resp?.body?.presigned_url;
  if (!preSignedUrl) {
    throw new Error("Not able to fetch preSignedUrl");
  }
  try {
    await request.put(preSignedUrl).send(fileBlob);
    const fileUploadResult = [
      {
        url: fileStorageFileKey,
        filename: `${shiftId}.png`,
        fileStorageFileKey: fileStorageFileKey,
      },
    ];
    return fileUploadResult;
  } catch (error) {
    throw new Error(`Not able to upload ${error}`);
  }
};

export const getContentType: Record<string, string> = {
  jpg: "image/jpeg",
  jpeg: "image/jpeg",
  png: "image/png",
  gif: "image/gif",
  webp: "image/webp",
  pdf: "application/pdf",
  doc: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  dotx: "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  xls: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  xltx: "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
  ppt: "application/vnd.ms-powerpoint",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  potx: "application/vnd.openxmlformats-officedocument.presentationml.template",
  ppsx: "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  odt: "application/vnd.oasis.opendocument.text",
  ods: "application/vnd.oasis.opendocument.spreadsheet",
  odp: "application/vnd.oasis.opendocument.presentation",
  rtf: "application/rtf",
  csv: "text/csv",
  txt: "text/plain",
};
