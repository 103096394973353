import { Reducer, useCallback, useLayoutEffect, useReducer, useRef } from "react";

const useSafeDispatch = (dispatch: any) => {
  const mounted = useRef(false);

  useLayoutEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return useCallback((...args) => (mounted.current ? dispatch(...args) : void 0), [dispatch]);
};

const asyncReducer = <TData = null>(
  _state: IAsyncState<TData>,
  action: { type: IAsyncState<TData>["status"]; data?: TData; error?: Error }
): IAsyncState<TData> => {
  switch (action.type) {
    case "pending": {
      return { status: "pending", data: null, error: null };
    }
    case "resolved": {
      return { status: "resolved", data: action.data ?? null, error: null };
    }
    case "rejected": {
      return { status: "rejected", data: null, error: action.error ?? null };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export interface IAsyncState<T> {
  status: "idle" | "pending" | "resolved" | "rejected";
  data: T | null;
  error: Error | null;
}
/**
 * @deprecated
 */
export const useAsync = <TData = any>(initialState: Partial<IAsyncState<TData>> = {}) => {
  const [state, unsafeDispatch] = useReducer<
    Reducer<IAsyncState<TData>, Record<"type", IAsyncState<TData>["status"]>>
  >(asyncReducer, {
    status: "idle",
    data: null,
    error: null,
    ...initialState,
  } as IAsyncState<TData>);

  const dispatch = useSafeDispatch(unsafeDispatch);

  const { data, error, status } = state;

  const run = useCallback(
    (promise: Promise<TData>) => {
      dispatch({ type: "pending" });
      promise.then(
        (data) => {
          dispatch({ type: "resolved", data });
        },
        (error) => {
          dispatch({ type: "rejected", error });
        }
      );
    },
    [dispatch]
  );

  const setData = useCallback((data: TData) => dispatch({ type: "resolved", data }), [dispatch]);
  const setError = useCallback((error) => dispatch({ type: "rejected", error }), [dispatch]);

  return {
    setData,
    setError,
    error,
    status,
    data,
    run,
  } as const;
};
